@import 'Styles/_theme.scss';

.page {
  background-image: url('./../../Resources/highdef-background-image.svg'); /* placeholder for now */
  background-position: bottom 3px right 20%;
  background-repeat: no-repeat;
  background-size: 656px 173px;
  height: calc(100% - 42px);
  min-height: calc(100vh - 206px);
}

.browseAllPage {
  min-height: calc(100% - 38px);
  padding-bottom: 140px;
}

.content {
  padding: 32px 35px;
}

.title {
  color: $black;
  font-family: Roboto;
  font-size: 25px;
}

.sortBy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 25px;
}
