@import 'Styles/_theme.scss';

.dragDropArea {
  height: 145px;
  font-size: 24px;
  color: $darkGray;
  background: $offWhite;
  border: 1px dashed $mediumGrey;
  border-radius: 4px;
}

.dragDropAreaHover {
  border: 1px dashed $darkestBlack;
  background: $lightGrey;
  cursor: grabbing;
}

.Description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $darkestGrey;
}

.SubText {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $mediumGrey;
}

.UploadIcon {
  color: $darkestGrey;
}

.FileLink {
  color: $primary;
}
