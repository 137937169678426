@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  padding: 2rem 0 0 0;
  max-width: 1434px;
  height: calc(100vh - (72px + 2rem));

  font-family: 'Roboto';
  font-weight: 400;

  background-image: url('./../../Resources/highdef-background-image.svg'); /* placeholder for now */
  background-position: bottom 42px right 2.5%;
  background-repeat: no-repeat;
  background-size: 656px 173px;

  overflow: auto;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & h2 {
    color: $black;
    font-size: 25px;
    line-height: 29.3px;
  }

  & span {
    color: $neutralGrey;

    font-size: 16px;
    line-height: 24px;
  }
}

.whitelist {
  flex: 1;
}

@media (max-width: $large-screen-breakpoint) {
  .wrapper {
    max-width: 980px;
    background-position: bottom 60px right 20%;
  }
}
