@import 'Styles/_theme.scss';

#autocompleteSearch {
  .MuiInputBase-input {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 13px;
    font-size: $ts-16;
  }
}
