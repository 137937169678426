@import 'Styles/_theme.scss';

.managePage {
  background-image: url('./../../Resources/loadingbackground.svg'); /* placeholder for now */
  background-position: bottom 20px right 12px;
  background-repeat: no-repeat;
  background-size: 403px 107px;
  min-height: calc(100vh - 115px);
  height: auto;
}

.title {
  color: $darkPrimary;
  margin: 10px 0 10px 11px;
  line-height: 23px;
  font-size: 25px;
  font-style: normal;
}

.banner {
  padding: 1rem 1.5rem;
  background-color: $lightGray;
}

.importContainerTitle {
  font-size: 25px;
  color: $darkPrimary;
  margin-left: 11px;
}

.importContainerBanner {
  margin: 10px 0;
}

.importContainer {
  background-color: $backgroundWhite;
  padding: 1rem 1.5rem;
}

.importToolContainer {
  padding-left: 20px;
  padding-right: 20px;
}

.dragDropArea {
  height: 100px;
  font-size: 24px;
  color: #555555;
  border: 2px #c3c3c3 dashed;
  border-radius: 12px;
}

.fileInput {
  height: 50px;
  width: 100%;
}

.featuresContainerTitle {
  margin-left: 11px;
  line-height: 23px;
}

.manageTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: $darkPrimary;
}
.manageTitleSeperator {
  height: 12px;
  color: $darkPrimary;
}

.featuresContainerBanner {
  margin: 10px 0;
}

.featuresContainer {
  background-color: $backgroundWhite;
  padding: 1rem 1.5rem;
  width: 100%;
}

.tabPanel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-self: stretch;
}

.manageTab {
  text-transform: capitalize;
  font-size: 18px;
  color: $mediumGrey;
  font-weight: 400;
  letter-spacing: 0;
  margin: 1px;
}
