@import 'Styles/_theme.scss';

:export {
  lightestGray: $lightestGray;
  lightGrey: $lightGrey;
  neutralGrey: $neutralGrey;
  darkestGrey: $darkestGrey;
  secondaryColor: $secondary-color;
  black: $black;
}

.dataGridRow {
  background-color: $white;
}

.dataTableTitleColumn {
  color: $black;
  font-weight: 600;
}

.activityTableTitleColumn {
  color: $black;
  font-weight: 600;

  &:focus {
    outline: 2px dotted $black;
    outline-offset: 5px;
  }
}

.showMoreOnOverflow {
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
  &:focus-visible {
    border: 2px dotted $darkestGrey;
  }
}

.singleItemActionsIcon {
  color: $darkestGrey;
}

.singleItemActions {
  &:focus-visible {
    background-color: $offWhite;
  }
}

.deleteOption {
  color: $secondary-color;
  &:hover {
    background-color: $lightGrey;
  }
}

.suppressOption {
  color: $black;
  &:hover {
    background-color: $lightGrey;
  }
}

.primaryStatusChip {
  color: $black;
  z-index: 1;
  text-transform: uppercase;
  font-size: 12px;
  padding: 1px 6px 0px 6px;
}

.secondaryStatusChip {
  color: $black;
  margin-left: -5px;
  z-index: 0;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-size: 12px;
  padding: 1px 6px 0px 6px;
}

.filterActionsButton {
  background-color: $white;
  color: $black;
  height: 40px;
  text-transform: none;
  border: 1px solid black;
}

.toolbar {
  padding: 12px;
}

.rightWrapper {
  width: 100%;
}

.uploadContentButton {
  width: 177px;
}

.filterSelectionIcon {
  color: $black;
}

.filterSelectionTab {
  text-transform: capitalize;
  font-size: 16px;
}

.filterSelectionClearButton {
  color: $black;
  text-transform: none;
  font-size: 16px;
  justify-content: flex-start;
  padding: 10px 20px;
}

.selectionButton {
  color: $darkestGrey;
  font-size: 16px;
  line-height: 40px;
  margin: 0 8px;
  white-space: nowrap;
  cursor: default;
  &:hover {
    background-color: transparent;
  }
}

.divider {
  color: $offWhite;
}

.dropDown {
  width: 195px;
  color: $darkestGrey;
  &:hover {
    background-color: $offWhite;
  }
}

.padding {
  padding-left: 8px;
}

.menuItem {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: $darkestGrey;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
}

.deleteIcon {
  border-top: 1px solid $lightGrey;
}

.suppressionMessageContainer {
  width: 512px;
  height: 136px;
}

.suppressionMessageSubTitle {
  vertical-align: top;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: $black;

  margin-top: 20px;
}

.suppressionMessageText {
  vertical-align: bottom;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: $black;
  margin-top: 35px;
}

.cellTooltip {
  top: 5px;
  max-width: 387px;
  padding: 7px;
  border-radius: 3px;
  border: none;
  outline: none;
}

.dataTableColumnHeader {
  font-weight: 700;
  outline: none;
  border: none;
}

.rowCountSelect {
  margin: 5px 38px 5px 16px;
  width: 80px;
}

.searchIcon:focus-visible {
  background-color: $offWhite;
}

.paginationControls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowsPerPageLabel {
  color: $black;
}
