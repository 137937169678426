@import 'Styles/_theme.scss';

.refinementItem {
  text-transform: none;
  color: $black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;

  .refineItemTitle {
    flex-direction: row-reverse;
    align-items: flex-start;
    border: none;
    padding: 0 0 0 6px;
    margin: 0;
    color: $primary;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    min-height: auto;

    &:hover {
      text-decoration: underline;
    }

    &:focus-visible {
      background-color: transparent;
      outline: 2px dotted $black;
      outline-offset: -2px;
    }
  }

  .activeRefineItemTitle {
    font-weight: 700;
  }

  .refineItemIcon {
    color: $primary;
  }

  &::before {
    display: none;
  }
}

.dropdownArea {
  padding: 1px 0 !important;
  margin-left: 18px;
  border-left: 1px solid $lightGrey;
}

.showMaxButton {
  color: $primary;
  height: 44px;
  text-transform: none;
  margin-top: 16px;
  padding: 0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  .showMaxChipsArrow {
    margin-left: 8px;
  }
}
