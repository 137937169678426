@import 'Styles/_theme.scss';

.pagePopup {
  display: flex;
  width: 100%;
  max-width: 1500px;
  box-sizing: border-box;
  background-color: $white;
  color: $black;
  position: absolute;
  left: 0;
  right: 0;
  top: 164px;
  margin-left: auto;
  margin-right: auto;
  z-index: 6;
}

.backdrop {
  background-color: rgba($darkestBlack, 0.65);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
}

.controls {
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-top: 8px;
}

.content {
  flex-grow: 1;
  display: flex;
  gap: 20px;
  padding: 30px;
}

.closeButton {
  margin-bottom: 10px;
  color: $black;
}

.closeButton:focus-visible {
  outline: 2px dotted $black;
}

.description {
  background-color: rgba(204, 238, 231, 0.25);
  display: flex;
  padding: 16px;
  gap: 16px;
  border-radius: 8px;

  .icon {
    width: 60px;
    height: 60px;
  }

  .smallIcon {
    width: 40px;
    height: 40px;
  }

  .descriptionText {
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .showAllButton {
      color: $primary;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-transform: none;
      padding: 0;
      margin-top: 5px;

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
}
