@import 'Styles/_theme.scss';

.conceptForm {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.metadataBtn {
  margin-left: 10px;
  line-height: 23px;
  height: 44px;
}

.autocompleteListPaper {
  position: absolute;
  top: 44px;
  z-index: 1000;
  width: 506px;
}

.conceptList {
  color: $black;
  font-size: 16px;
  line-height: 30px;
  padding-left: 12px;
  text-align: left;
}

.listItem {
  cursor: pointer;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.errorText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $red;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0;
}
