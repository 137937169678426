@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  & h2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: $black;
  }

  & .returnMsg {
    margin-left: auto;

    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $black;
  }
}

.fileDetailsContainer {
  display: flex;
  flex-direction: column;

  height: calc(100% - 277px);
  margin: 8px 0 0 0;
  padding: 0 10px 10px 10px;
  overflow: auto;
  overflow-x: hidden;
}

@media screen and (max-height: 599px) {
  .wrapper {
    & h2 {
      padding: 0;
    }
    & p {
      margin: 5px 0;
    }
  }
  .fileDetailsContainer {
    margin: 0;
  }
}
