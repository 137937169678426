@import 'Styles/_theme.scss';

.card {
  flex: 1;
  text-align: left;
  padding: 0;
  background-color: $white;
  border: 1px solid $lightGrey;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(2px 1px 4px #f0f0f0);
  align-self: stretch;
  width: calc(100% / 3 - 11px);
  max-width: calc(100% / 3 - 11px);
  min-width: calc(33% - 11px);
  text-transform: inherit;
}

.cardContent {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  gap: 8px;
}

.cardMedia {
  width: 100%;
  margin-bottom: -8px;
}

.cardMediaContainer {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  position: relative;
  top: 100;
  display: block;
  overflow: hidden;
}

.cardFooter {
  flex-grow: 1;
  display: flex;
  height: 24px;
  align-items: flex-end;
  gap: 8px;
  color: #727272;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;

  & span {
    margin-bottom: -3px;
  }
}

.videoPlayIcon {
  fill: $white !important;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: auto;
}

.videoPlayIconContainer {
  position: absolute;
  inset: 50% 0 0 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 30px;
  padding: 3px 0 0;
  background-color: $darkestGray;
  border: 1px solid $white;
  border-radius: 8px;
  text-align: center;
  opacity: 0.9;

  svg {
    display: inline-block;
    height: 24px;
    width: 24px;
  }
}

.cardTitle {
  color: $primary;
  font-family: Roboto;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card:hover,
.card:focus-visible {
  border: 1px solid $neutralGrey;

  .cardTitle {
    text-decoration: underline;
    color: $darkPrimary;
  }

  .cardDesc {
    color: $mediumGrey;
  }

  .cardContent {
    background-color: $hoverWhite;
  }

  .cardCheckboxContainer {
    background-color: $hoverWhite;
  }
}

.card:focus-visible {
  outline: 2px dotted $black;
  outline-offset: 2px;
}

.cardDesc {
  display: -webkit-box;
  color: $neutralGrey;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 48px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardCheckboxContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: $white;
  border-top-left-radius: 7px;
}

.textWrapper {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 15px 10px;
  text-transform: inherit;

  &:focus-visible,
  &:hover {
    background-color: $hoverWhite;

    .textDesc {
      color: $darkestBlack;
    }

    .textTitle {
      color: $darkPrimary;
    }
  }

  &:focus-visible {
    outline: 2px dotted $black;
  }
}

.textTitleAndDesc {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  margin: 8px 0;
  color: $neutralGrey;
  text-align: left;
  width: 0;

  .textTitle {
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
    color: $primary;
    white-space: wrap;
  }

  .textDesc {
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $neutralGrey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $black;
  }
}

.textWrapper:hover .textTitleAndDesc,
.textWrapper:focus-visible .textTitleAndDesc {
  .textTitle {
    text-decoration: underline;
  }
}

.textHwidAndIcon {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
  justify-content: flex-end;
  color: $mediumGrey;
  line-height: 19px;
  max-width: 25%;
  overflow-wrap: break-word;
  white-space: wrap;
  text-align: right;
  font-family: $fontFamily;
  font-weight: 400;

  .textHwid {
    font-size: 16px;
    flex-grow: 1;
    margin: 0;
    width: 80%;
  }

  .textIcon {
    margin-left: 10px;
  }
}
