@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid $lightGrey;
  background: $white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  padding: 24px;
}

.aspects {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
}

.tabs {
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
}

.tab {
  color: $neutralGrey;
  padding: 9px 18px;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  border-bottom: 2px solid $lightGrey;
}

.commonBtn {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: unset !important;
  padding: 0;
  min-width: 0;
}

.unselectedBtn {
  color: $primary;
}

.selectedBtn {
  color: $black;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.text {
  position: relative;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  flex-grow: 1;
  padding-bottom: 30px;

  & h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29.3px;
    color: $black;
  }

  & details {
    display: none;
    visibility: hidden;
  }
}

.hwid {
  position: absolute;
  bottom: 0;
  color: #727272;
}

.topicError {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  width: 60%;
}

.controls {
  min-width: 40%;
  max-width: 40%;
  background-color: $lightestGray;
  margin-left: auto;
  margin-right: 0;
  border-radius: 8px;
  padding: 16px;
  align-self: flex-start;
}

.showInstructionsDropDown {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 3px;
  font-family: Roboto;
  font-size: 16px;
  text-transform: none;
  height: 100%;
  min-width: 0;
  padding: 20px 0 0 0;
  color: $darkPrimary;
}

.showInstructionsDropdownLabel {
  font-size: 16px;
  white-space: nowrap;
}

.loadingSpinnerWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
