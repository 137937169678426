@import 'Styles/_theme.scss';

.tooltipMessage {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: $white;
}

.tooltip {
  top: 5px;
  width: 387px;
  padding: 7px;
  border-radius: 3px;
}
