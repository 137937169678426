@import 'Styles/_theme.scss';

.errorpage {
  padding-top: 10%;
  background-color: $darkGray;
  height: 100%;
  width: 100%;
}

.error {
  border-radius: 8px;
  background-color: $white;
  padding: 20px;
}

.title {
  font-size: x-large;
  color: $red;
  border-bottom: 2px;
}
