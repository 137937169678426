@import 'Styles/_theme.scss';

#confirmModal {
  padding: 24px 0;
  max-width: 560px;
}

.margin {
  margin-left: 24px;
  margin-right: 24px;
}

.modalConfirmTitle {
  color: $red;
  font-weight: 500;
  font-size: 25px;
  line-height: 29px;
  margin: 0px 24px 10px 24px;
}

.modalContent {
  overflow-y: hidden;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  color: $darkestGrey;
  margin-top: 10px;
  padding: 10px 24px;
  background-color: $tan;
  &:focus-visible {
    outline: none;
    border-radius: 8px;
    border: 2px dotted #424242;
  }
}

.modalConfirmMessage {
  overflow-y: hidden;
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  color: $darkestGrey;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0px 24px;
  & span {
    font-weight: 400;
  }
}

.modalList {
  list-style-type: none;
  padding: 0px 24px;
  line-height: 34px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow-y: scroll;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  li:focus-visible {
    outline: none;
    border-radius: 8px;
    border: 2px dotted #424242;
  }
}

.confirmTextInput {
  width: calc(100% - 48px);
  margin: 12px 24px 0 24px;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin: 34px 24px 0px 24px;
}

.textFieldError {
  color: $red;
  margin: 4px 24px 0;
  font-size: $ts-12;
}
