@import 'Styles/_theme.scss';

/* Modal Content/Box */
.modalContent {
  max-width: 560px;
  max-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 10px;
  background-color: $white;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 50px auto; /* 50px (close to 48dp recommended for design) from the top and centered */
}

.modalTitle {
  color: $darkPrimary;
  font-weight: 500;
  font-size: 25px;
  line-height: 33px;
  margin: 0px;
}

.suppressionMessageContainer {
  max-width: 512px;
  max-height: 376px;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  font-weight: 400;
}

.suppressListContainer {
  max-height: 195px;
  overflow: auto;
}

.suppressListContainer::-webkit-scrollbar {
  display: none;
}

.buttonPanel {
  margin-top: 20px;
}

.bottomMargin {
  margin-bottom: 25px;
}

.topMargin {
  margin-top: 40px;
}

.bold {
  font-weight: 500;
}
