@import 'Styles/_theme.scss';

.modalConfirm {
  width: 560px;
  height: 217px;
  padding: 15px 24px 15px 24px;
}

.modalConfirmTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;

  /* primary-dark */

  color: $darkPrimary;
}

.modalMessage {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}
