@import 'Styles/_theme.scss';

.container {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.breadCrumbsAndPage {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page {
  background-image: url('./../../Resources/highdef-background-image.svg'); /* placeholder for now */
  background-position: bottom 3px right 20%;
  background-repeat: no-repeat;
  background-size: 656px 173px;
  height: calc(100% - 42px);
  min-height: calc(100vh - 206px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.wrapper {
  width: 100%;
  padding: 0 32px 110px 32px;
  display: flex;
  flex-direction: column;
  height: auto;
}

.wrapper .loader {
  position: absolute;
  left: 50%;
  right: 50%;
}

.landingHeader {
  color: $darkestBlack;
  padding: 60px 0;
  text-align: center;
  font-family: Roboto Slab;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sectionContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 17px;
  margin: 26px 0 16px 0;
  width: 100%;
  overflow-anchor: none;
}

.sectionIcon {
  width: 30px;
  height: 30px;
}

.sectionTitle {
  color: $darkestBlack;
  font-family: Roboto Slab;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sectionLine {
  flex-grow: 1;
  height: 1px;
  margin-left: 6px;
  background-color: $lightGrey;
}

.paddingBox {
  height: 32px;
}

.showAllButton {
  color: $primary;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  padding: 0;
  margin-top: 5px;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}

.largePaddingBox {
  height: 63px;
}
