@import 'Styles/_theme.scss';

.cmxTextFieldTitle {
  font-size: 14px;
  line-height: 16px;
  color: $darkestGrey;
  margin-bottom: 2px;
}

.cmxTextFieldSupportText {
  font-size: 14px;
  line-height: 16px;
  color: $neutralGrey;
  margin-bottom: 6px;
}

.disabledText {
  color: $neutralGrey;
  margin-bottom: 6px;
}

.cmxTextfieldErrorMessage {
  font-size: 14px;
  line-height: 16px;
  color: $red;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 7px;
  margin-bottom: 3px;
}
