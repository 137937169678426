@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $black;
}

.btnGroup {
  display: flex;
  flex-direction: row;

  background-color: $lightGrey;
  border-radius: 20px;

  & button {
    flex-grow: 1;
  }
}

.checkboxes {
  display: flex;
  flex-direction: column;
}

.refinementItem {
  text-transform: none;
  color: $black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;

  .refineItemTitle {
    flex-direction: row-reverse;
    align-items: flex-start;
    border: none;
    padding: 0 0 0 6px;
    margin: 0;
    color: $primary;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    min-height: auto;

    &:hover {
      text-decoration: underline;
    }

    &:focus-visible {
      background-color: transparent;
      outline: 2px dotted $black;
      outline-offset: -2px;
    }
  }
}

.embedCopy {
  display: flex;
  gap: 8px;

  .copy,
  .copy:hover {
    border-radius: 8px;
    background-color: $primary;
    color: $white;
    height: 44px;
    width: 60px;

    &:focus-visible {
      outline: 2px dotted #424242 !important;
    }
  }
}

.approvedSites {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  font-size: 14px;
  color: $black;

  & button {
    padding: 0;
    height: 24px; // This cannot go any lower unless we shrink the svg
    font-size: 14px;
    color: $darkPrimary;
    text-decoration: underline;
  }

  & img {
    margin: 0 0.5rem 0 0;
  }
}
