@import 'Styles/_theme.scss';

.group {
  box-shadow: none;
  height: 44px;
  & :hover {
    background: $green;
  }
}

.group > button {
  background: $green;
  font-family: $fontFamily;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: $white;
  border-radius: 8px;
  height: 44px;
}

.label {
  border-right: 1px solid $white !important;
}
