.htmlArticleContent {
  width: 100%;
  min-height: 50px;
}

.pdfArticleContent {
  width: 100%;
  min-height: 50px;
  overflow-y: hidden;
}

.iFrame {
  width: 100%;
  height: 100%;
  border: none;
}
