@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  max-height: calc(100% - 160px);
  width: 680px;
  padding: 30px;
  margin: 50px auto; /* 50px (close to 48dp recommended for design) from the top and centered */

  border-radius: 8px;
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.039), 0px 10.5px 36px rgba(0, 0, 0, 0.19);
  background-color: #fefefe;
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading h2 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
}

.separator {
  width: 100%;
  border-top: 1px solid $offWhite;
  margin: 24px 0;
}

.steps {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 0 0 0;
  gap: 16px;
}

.errorText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: $red;
  padding: 15px 0 0 0;
}

.buttonPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.rightButtons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.modalConfirm {
  width: 560px;
  height: 209px;
  padding: 15px 24px 15px 24px;
}

.modalConfirmTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;

  /* primary-dark */

  color: $darkPrimary;
}

.modalMessage {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

@media screen and (max-height: 599px) {
  .wrapper {
    margin: 10px auto;
    padding: 10px 30px;
    max-height: calc(100% - 40px);
  }

  .steps {
    padding: 0 0 0 0;
  }
  .separator {
    margin: 5px 0;
  }
  .errorText {
    padding: 5px 0 0 0;
  }
}
