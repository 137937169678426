@import 'Styles/_theme.scss';

.title {
  color: $darkPrimary;
  margin: 10px 0 10px 11px;
  line-height: 23px;
  font-size: 25px;
  font-style: normal;
}

.banner {
  padding: 1rem 1.5rem;
  background-color: $lightGray;
}

.sectionTitle {
  color: $black;
  font-weight: 500;
  font-size: 18px;
  margin-top: 25px;
}

.flippers {
  margin: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
}
