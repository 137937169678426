@import 'Styles/_theme.scss';

.footer {
  left: 0;
  right: 0;
  height: auto;
  font-size: $ts-12;
  font-weight: 400;
  text-decoration: none;
  white-space: pre-wrap;

  & p {
    color: $mediumGrey;
    display: flex;
    flex-direction: row;
    line-height: 18px;
    margin: 12px 0 0 0;

    .copyright {
      color: $mediumGrey;
    }

    .privacyLink {
      color: $mediumGrey;
      padding-right: 8px;
      text-decoration: underline;
      white-space: nowrap;
    }

    .termsLink {
      color: $mediumGrey;
      padding-left: 8px;
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  & hr {
    width: 100%;
    border: 1px solid $lightGrey;
  }
}

.normalFooter {
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 18px;
  background: $darkestGrey;
  color: $white;

  & p {
    text-decoration: none;
    white-space: pre-wrap;
    margin-left: 37px;
    display: inline-block;
  }

  .link {
    white-space: nowrap;
    text-decoration: underline;
    padding: 0 8px;
    color: $white;
  }
}

.links {
  display: flex;
  flex-direction: row;
  top: auto;
  line-height: 18px;

  .copyright {
    color: $mediumGrey;
  }

  .footerLink {
    white-space: nowrap;
    color: $mediumGrey;
    text-decoration: underline;
  }

  .privacyLink {
    padding-right: 8px;
  }

  .termsLink {
    padding-left: 8px;
  }
}

.line {
  color: $lightGrey;
  border-bottom-width: 1px;
}
