$green: #0d8484; //PrimaryButton, User info Background, icons (Most of them)
$primary-color: #0d8484;
$primary: #0d8484;
$lightBlue: #00a2c7;

$darkerGreen: #006272; //Header Background, Primary Text Color, checkbox checked color,
$darkPrimary: #006272;
$successGreen: rgba(47, 167, 135, 0.3);

$red: #dd372f; //(Basket number, Current page bar)
$secondary-color: #dd372f;

$lightRed: rgba(221, 55, 47, 0.3);
$tan: #fff1d1;

$pink: #f8d7d5; //Clear all facet pill
$darkestBlack: #262626;
$black: #424242; //Standard text color
$darkestGray: #0000008a; //Search bar icons
$darkestGrey: #424242;
$darkGray: #707070; //Tolltip Background
$neutralGrey: #727272;
$mediumGrey: #676767;
$gray: #e0e0e0; //Facet pills
$lightGrey: #d8d8d8;
$lighterGray: rgba(216, 216, 216, 0.25); //embed toggles
$lightestGray: #f8f8f8; //Search bar list hover
$white: #ffffff; //Light Text Color
$hoverWhite: #f6fafa;
$offWhite: #f4f4f4;
$neutralDark: #ebebeb;

$lightGray: #f5f4f6; //Cart Header Background, facets background, search background
$backgroundWhite: #f5f4f6;

$disabled: rgba(0, 0, 0, 0.38); //Material UI color used to keep the theme consistent in filters

$fontFamily: 'Roboto', 'Helvetica', 'Arial', sans-serif;

$ts-4: 0.25rem;
$ts-8: 0.5rem;
$ts-12: 0.75rem;
$ts-14: 0.875rem;
$ts-16: 1rem;
$ts-18: 1.125rem;
$ts-20: 1.25rem;
$ts-24: 1.5rem;
$ts-30: 1.875rem;
$ts-32: 2rem;
$ts-36: 2.25rem;
$ts-38: 2.375rem;
$ts-40: 2.5rem;
$ts-48: 3rem;
$ts-54: 3.375rem;
$ts-64: 4rem;

$ss-2: 0.125em;
$ss-3: 0.188em;
$ss-4: 0.25em;
$ss-6: 0.375em;
$ss-8: 0.5em;
$ss-10: 0.625em;
$ss-12: 0.75em;
$ss-11: 0.688em;
$ss-14: 0.875em;
$ss-15: 0.938em;
$ss-16: 1em;
$ss-18: 1.125em;
$ss-20: 1.25em;
$ss-24: 1.5em;
$ss-28: 1.75em;
$ss-30: 1.875em;
$ss-32: 2em;
$ss-34: 2.125em;
$ss-35: 2.188em;
$ss-36: 2.25em;
$ss-40: 2.5em;
$ss-42: 2.625em;
$ss-45: 2.813em;
$ss-48: 3em;
$ss-50: 3.125em;
$ss-56: 3.5em;
$ss-58: 3.625em;
$ss-64: 4em;
$ss-80: 5em;

$hs-1: $ts-36;
$hs-2: $ts-24;
$hs-3: $ts-18;
$hs-4: $ts-16;
$hs-5: $ts-14;
$hs-6: $ts-12;

$large-screen-breakpoint: 1434px;

// @include this in any component that uses an outline.
// Add 'dark' class for a light outline.
@mixin dotted-outline-on-focus {
  &:focus-visible {
    outline-color: $black;
    outline-style: dotted;
    outline-width: 2px;
    outline-offset: 2px;
  }

  &.dark:focus-visible {
    outline-color: $white;
  }
}
