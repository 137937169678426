@import 'Styles/_theme.scss';

.searchForm {
  width: 100%;
  position: relative;

  .searchIcon {
    margin-right: -5px;
    margin-left: -10px;
  }

  .closeIcon {
    margin-right: -10px;
    color: $black;
  }
}

.listItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}

.listItem:focus {
  border: none;
  outline: 2px dotted $black;
}

.autoLink {
  text-decoration: none;
  color: $black !important;
  &:hover {
    cursor: pointer;
  }
}

.deleteLink {
  display: table-cell;
  width: auto;
  padding-top: 9px;
  &:hover {
    cursor: pointer;
  }
}

.autoLink:focus {
  outline: var(--focus-indicator, 2px dotted $black);
  outline-offset: 0;
}

.conceptListItemIcon {
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 12px;
  display: table-cell;
  width: 35px;
  min-width: 35px;
  color: $mediumGrey;
}

.contentListItemIcon {
  width: 35px;
  padding-top: 2px;
  color: $darkestGray;
}

.suggestListItemIcon {
  padding-top: 9px;
  padding-left: 12px;
  display: table-cell;
  width: 35px;
  color: $darkestGray;
}

.conceptListItemTitle {
  width: auto;
  flex-grow: 1;
  min-width: 0;
}

.contentListItemTitle {
  padding-left: 12px;
  flex-grow: 1;
  min-width: 0;
}

.historyListItemAction,
.contentListItemAction {
  padding: 10px 12px 0 12px;
  margin-right: 0;
  margin-left: auto;
}

.typeIcon {
  color: $neutralGrey;
  margin-bottom: -7px;
}

.deleteIcon {
  color: $black;
}

.enterNote {
  color: $black;
  font-family: Roboto;
  font-size: 12px;
  padding-top: 12px;
}

.itemTitle {
  font-weight: 400;
  padding-top: 9px;
}

.autocompleteListPaper {
  position: absolute;
  z-index: 7 !important;
  width: 100%;
  border-radius: 0 0 8px 8px;
}

.autocompleteListPopper {
  width: 100%;
  position: absolute;
  z-index: 7 !important;
  left: 0;
  bottom: 0;
  padding: 0;
  display: none;

  &.autocompleteListPopperOpen {
    display: block;
  }

  .MuiList-padding {
    padding: 0.5rem 0 0;
  }
}

.listItem:hover {
  background-color: $lightestGray;
  transition: background-color 0.3s ease-out;
}

.autocompleteSection {
  border-top: 1px solid $lightGrey;
}

.autocompleteSectionTitle {
  color: $neutralGrey;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0 0 10px;
}
