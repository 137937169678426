@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contentCopy {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  height: 44px;

  & button {
    border-radius: 8px;
    height: 40px;
    background-color: $primary;
    box-shadow: none;
    &:hover {
      background-color: $primary;
      box-shadow: none;
    }
    &:focus-visible {
      outline: 2px dotted #424242 !important;
    }
  }
}

.attributionSection {
  color: $black;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  .attributionTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .attributionSummary {
    color: $black;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .attributionQuote {
    font-weight: 500;
  }
}

.contentCopy {
  display: flex;
  gap: 8px;

  .copy,
  .copy:hover {
    border-radius: 8px;
    background-color: $primary;
    color: $white;
    height: 44px;
    width: 60px;
  }
}

.disclaimerText {
  * {
    display: inline;
  }
}

.showInstructionsDropDown {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 3px;
  font-family: Roboto;
  font-size: 16px;
  text-transform: none;
  height: 100%;
  min-width: 0;
  padding: 20px 0 0 0;
  color: $darkPrimary;
}

.showInstructionsDropdownLabel {
  font-size: 16px;
  white-space: nowrap;
}
