@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  & h2 {
    padding: 0 0 16px 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: $black;
  }
}

.border {
  border: 1px solid $offWhite;
  border-radius: 8px;
  margin: 5px 0;
}

.borderError {
  border: 1px solid $red;
  border-radius: 8px;
  margin: 5px 0;
}

.fileName {
  display: flex;
  align-items: center;
  gap: 5px;

  padding: 8px 0 0 8px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  overflow-wrap: anywhere;
}

.statusIcon {
  height: 25px;
}
.successIcon {
  height: 25px;
  color: $green;
}
.deleteIcon {
  color: $red;
  margin-left: auto;
  margin-right: 5px;
}

label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $black;
}

.cancelled {
  margin: 0 0 0 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $red;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0 13px 0;
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;

  & label {
    float: right;
    margin-top: 14px;
  }

  & p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $red !important;
    margin: 5px 0 0 0;
  }
}

.field {
  width: calc(100% - 125px);
  padding-right: 8px;
}

.langDropdown {
  width: 195px;
}

.errorText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $red;
  margin: 0;
  & u {
    cursor: pointer;
  }
}
.tooltip {
  margin-left: 8px;
}
.overwriteMessage {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $black;
  margin: 0 0 0 5px;
}
.dupeError {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 0 5px;
}
.errorIcon {
  color: $red;
}

@media screen and (max-height: 599px) {
  .fileName {
    padding: 0 0 0 8px;
  }
  .rows {
    gap: 2px;
    padding: 0;
  }
  .border {
    margin: 0;
  }
}

.uploadCancelled {
  text-decoration: line-through;
}
