@import 'Styles/_theme.scss';

:export {
  black: $black;
  darkestGrey: $darkestGrey;
  green: $green;
  lightGrey: $lightGrey;
  red: $red;
  offWhite: $offWhite;
}

.menuItem {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: $darkestGrey;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.placeholder {
  margin-top: 2px;
  color: $black;
}
