@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  gap: 15px;
}

.pdfIconBackground {
  background-color: rgba(204, 238, 231, 0.25);
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: 50%;

  .pdfIcon {
    color: $black;
    position: relative;
    height: 24px;
    width: 24px;
    left: calc(50% - 23px / 2);
    top: calc(50% - 23px / 2);
  }
}

.fileDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
}

.fileName {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  overflow-wrap: anywhere;
}

.removeItem {
  margin: 0 8px 0 0;
  margin-left: auto;
  width: 44px;
  height: 44px;
  color: $red;
}

.fileInfo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $neutralGrey;
}

.errorText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $red;
  & u {
    cursor: pointer;
  }
}

.rightError {
  margin-left: auto;
}
.fileInfoText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $black;
}

.statusIcon {
  height: 25px;
  margin-left: 5px;
}
.successIcon {
  color: $green;
}

.uploadCancelled {
  text-decoration: line-through;
}

@media screen and (max-height: 599px) {
  .fileDetails {
    gap: 0px;
  }
}
