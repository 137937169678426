@import 'Styles/_theme.scss';

.banner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0;
}

.drawer {
  padding: 24px;
  min-width: 70%;
  max-width: 70%;
  height: calc(100% - 48px);
}

.smallWrapper {
  display: flex;
  flex-direction: column;
  min-width: 230px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 316px;
  padding: 24px;
  background-color: $offWhite;
  border-radius: 8px;
  margin: 0 0 24px 32px;
}

.wrapperTitle {
  color: $black;
  font-family: Roboto Slab;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 12px;
}

.icon {
  color: $neutralGrey;
  margin-left: -5px;
  vertical-align: middle;
}

.stickyWrapper {
  margin: 0 -24px -24px -24px;
  background-image: linear-gradient(transparent, $white, $white);
  position: sticky;
  bottom: -24px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.closeDrawer {
  pointer-events: all;
  margin: 32px 24px 24px 24px;
  flex-grow: 1;
}

.filterButton {
  width: fit-content;
  border-radius: 4px;
  border: 1px solid $neutralGrey;
  background-color: $white;
  color: $black;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: inherit;
  height: 42px;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    border: 1px solid $darkestBlack;
  }
}

.filterBadge {
  margin: 10px;
}

.filterBadge span {
  background-color: $darkPrimary;
  color: $white;
}
