@import 'Styles/_theme.scss';

:export {
  lightestGray: $lightestGray;
  offWhite: $offWhite;
  red: $red;
  white: $white;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.controls {
  & svg {
    margin: 0 0.5rem 0 0;
  }
  color: $primary;
}

.btn {
  color: $primary;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin: 1rem 0 0 0;
}

.listItemWrapper p {
  display: flex;
  align-items: center;
  margin: 0 0 0 1rem;
  color: $red;
}

.listItem {
  display: flex;
  align-items: center;

  height: 3rem;
  padding: 0.5rem 1rem 0.5rem 1rem;

  color: $mediumGrey;
  background-color: $lightestGray;
  border-radius: 0.5rem;

  & :last-of-type {
    margin-left: auto;
  }
}

.btnGroup {
  display: flex;
  gap: 8px;

  & button {
    min-width: unset;
    color: $black;
  }
}
