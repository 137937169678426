@import 'Styles/_theme.scss';

:export {
  darkPrimary: $darkPrimary;
}

.facet {
  background-color: transparent;
  border: none;
  box-shadow: none;

  &::before {
    display: none;
  }

  .facetHeader {
    display: flex;
    width: fit-content;
    gap: 3px;
    margin: 0;
    padding: 0;

    .facetTitle {
      color: $black;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }

    .facetHeaderIcon {
      color: $black;
    }
  }

  .facetContent {
    padding: 0;
    margin-bottom: 12px;
  }
}

.facetDivider {
  margin-top: 12px;
  margin-bottom: 12px;
}

.item {
  font-size: $ts-16;
  font-weight: 400;
  line-height: 19px;

  & span {
    word-break: normal;
    white-space: normal;
  }
}

.showMore {
  margin: 12px 0;
  padding: 0;
  color: $primary;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  text-transform: none;
}
