@import 'Styles/_theme.scss';

:export {
  lighterGray: $lighterGray;
  white: $white;
  mediumGrey: $mediumGrey;
  black: $black;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: $black;
  }

  & button {
    flex-grow: 1;
  }
}
