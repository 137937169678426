@import 'Styles/_theme.scss';

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 9px;
  margin: 0;

  .checkbox {
    padding: 0;
    word-wrap: break-word;
    color: $black;
  }

  .activeCheckbox {
    color: $darkPrimary;
  }

  .disabledCheckbox {
    color: $disabled;
  }

  .label {
    color: $black;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .disabledLabel {
    color: $disabled;
  }
}
