@import 'Styles/_theme.scss';

.reportPage {
  background-image: url('./../../Resources/loadingbackground.svg'); /* placeholder for now */
  background-position: bottom 20px right 12px;
  background-repeat: no-repeat;
  background-size: 403px 107px;
  height: auto;
  min-height: calc(100vh - 114px);
}

.title {
  color: $darkPrimary;
  margin: 10px 0 10px 11px;
  line-height: 23px;
  font-size: 25px;
  font-style: normal;
}

.banner {
  padding: 1rem 1.5rem;
  background-color: $lightGray;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 25px;
  width: 100%;
}

.rounded {
  border-radius: 4px;
}

.cardRow {
  display: flex;
  flex-direction: row;
}

.reportCard {
  margin-left: 24px;
  margin-top: 24px;
  background: $white;
  border: 1px solid $lightGrey;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  cursor: pointer;
  text-align: left;
  &:hover,
  &:focus {
    border: 1px solid $darkPrimary;
    outline: none;
  }
  text-decoration: none;
}

@media (min-width: 517px) {
  .reportCard {
    width: 493px;
    height: 153px;
  }
}

.reportCardDesc {
  font-weight: 400;
  font-size: $ts-16;
  color: $neutralGrey;
  margin-top: 0;
  margin-right: 11px;
  line-height: 19px;
}

.inventoryReportIcon {
  height: 76px;
  width: 76px;
  padding-left: 20px;
  padding-top: 15px;
  vertical-align: middle;
}

.toolbar {
  height: 72px;
}

.divider {
  display: block;
}

.reportContainerMain {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 16px;
}

.reportContainerTitle {
  font-weight: 400;
  font-size: 1.25rem;
  color: $black;
  margin-top: 21.5px;
  margin-bottom: 6px;
}

.reportLinks {
  display: flex;
  color: $primary;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  justify-content: center;
  padding: 2px;
}
