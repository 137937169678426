@import 'Styles/_theme.scss';

$fieldLabelWidth: 100px;
$rowGap: 26px;

.halfBlock {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.thirdBlock {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.blockLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $neutralGrey;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.horizontalList {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    display: inline;
  }

  & li:after {
    content: ', ';
  }

  & li:last-child:after {
    content: '';
  }
}

.listItem:not(.showMore) {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  color: $darkestGrey;
  margin-top: 5px;
}

.showMore {
  margin: 5px 0;
  padding: 10px;
  width: fit-content;
  font-size: $ts-16;
  font-weight: 400;
  line-height: 16px;
  color: $black;
  border: 1px solid $neutralGrey;
  text-transform: none;

  &:focus-visible,
  &:hover {
    background-color: $neutralGrey;
    color: $white;
  }
}
