@import 'Styles/_theme.scss';

.loadingContent {
  padding-top: 25px;
  width: 560px;
  border-radius: 8px;
  background-color: $white;
}

.loadingTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #424242;
  padding-bottom: 5px;
}

.loadingDuration {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #727272;
  padding-bottom: 10px;
}

.loadingImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loadingSpinnerContainer {
  width: 100%;
  height: 126px;
  background-image: url('../../Resources/highdef-background-image.svg');
  background-size: 408px;
  background-repeat: no-repeat;
  background-position: center;
}

.loadingSpinner {
  position: relative;
  margin-left: calc(50% - 20px);
  top: 10px;
}

.loadingErrorFinish {
  width: 80px;
  position: relative;
  margin-left: calc(50% - 40px);
  top: 10px;
}

.loadingError {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $red;
  margin: 20px;
  white-space: pre-wrap;
  max-height: 260px;
  overflow: auto;
}

.loadingError::-webkit-scrollbar {
  display: none;
}

.loadingSummary {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $red;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}
