@import 'Styles/_theme.scss';

:export {
  black: $black;
  darkestBlack: $darkestBlack;
  white: $white;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  padding-right: 10px;
}

.commonBtn {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: unset !important;
  padding: 0;
  min-width: 0;
}

.unselectedBtn {
  color: $primary;
}

.selectedBtn {
  color: $black;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.media {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  flex-grow: 1;
  max-width: 60%;

  & h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 29.3px;
    color: $black;
  }

  & video {
    max-width: 100%;
  }

  & img {
    max-width: 100%;
  }
}

.topicError {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.controls {
  min-width: 40%;
  max-width: 40%;
  background-color: $lightestGray;
  margin-left: auto;
  margin-right: 0;
  border-radius: 8px;
  padding: 16px;
  align-self: flex-start;
}

.showInstructionsDropDown {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 3px;
  font-family: Roboto;
  font-size: 16px;
  text-transform: none;
  height: 100%;
  min-width: 0;
  padding: 0;
  margin: 20px 0 0 0;
  color: $darkPrimary;
  border-radius: 0;
}

.showInstructionsDropdownLabel {
  font-size: 16px;
  white-space: nowrap;
}

.loadingSpinnerWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.embedCopy {
  display: flex;
  gap: 8px;

  .copy {
    border-radius: 8px;
    background-color: $primary;
    color: $white;
    height: 44px;
    width: 60px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $black;
  margin-top: 8px;
}

.checkboxes {
  display: flex;
  flex-direction: column;
}

.label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: $black;
  padding: 8px 0;
}

.attributionWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
  font-size: 14px;
}

.contentCopy {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  height: 44px;

  & button {
    border-radius: 8px;
    height: 40px;
    background-color: $primary;
    box-shadow: none;
    &:hover {
      background-color: $primary;
      box-shadow: none;
    }
  }
}

.attributionSection {
  color: $black;
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  .attributionTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .attributionSummary {
    color: $black;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .attributionSubTitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .attributionQuote {
    font-weight: 500;
  }
}

.contentCopy {
  display: flex;
  gap: 8px;

  .copy {
    border-radius: 8px;
    background-color: $primary;
    color: $white;
    height: 44px;
    width: 60px;
  }
}

.disclaimerText {
  * {
    display: inline;
  }
}

.approvedSites {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin: 0.5rem 0 0 0;
  font-size: 14px;
  color: $black;

  & button {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: $darkPrimary;
    text-decoration: underline;
  }

  & img {
    margin: 0 0.5rem 0 0;
  }
}
