@import 'Styles/_theme.scss';

$modalPadding: 24px;

/* Modal Content/Box */
.modalContent {
  max-width: 560px;
  max-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $modalPadding;
  gap: 10px;
  background-color: $white;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 50px auto; /* 50px (close to 48dp recommended for design) from the top and centered */
}

.title {
  color: $darkPrimary;
  font-size: 25px;
  font-weight: 500;
  line-height: 33px;
  margin: 0px;
}

.warningMessage {
  width: 100%;
  margin-left: -$modalPadding;
  margin-right: -$modalPadding;
  padding: 12px $modalPadding;
  background-color: $tan;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  color: $darkestBlack;
  font-weight: 500;
}

.text {
  color: $darkestGrey;
  padding: 10px 0 15px 0;
  margin-top: 25px;
}

.boldtext {
  color: $darkestGrey;
  font-weight: 500;
  margin-top: 20px;
}

.controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
}

///////////////////////////////////
///
.publishListContainer {
  max-height: 195px;
  overflow: auto;
  color: $black;
}

.publishListContainer::-webkit-scrollbar {
  display: none;
}

.buttonPanel {
  margin-top: 20px;
}

.bottomMargin {
  margin-bottom: 25px;
}

.topMargin {
  margin-top: 40px;
}

.bold {
  font-weight: 500;
}
