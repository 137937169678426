@import 'Styles/_theme.scss';

:export {
  lightestGray: $lightestGray;
  lightGrey: $lightGrey;
  neutralGrey: $neutralGrey;
  darkestGrey: $darkestGrey;
  secondaryColor: $secondary-color;
  black: $black;
}

.metadataEditSectionTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: $black;
  padding-top: 25px;
}

.metadataEditSectionSubTitle {
  padding-top: 48px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: $black;
  display: flex;
  justify-content: baseline;
}

.recommendedField {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: $black;
  margin-left: 5px;
}

.metadataEditSectionDescription {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 139.69%;
  color: $black;
}

.metadataEditField {
  padding-top: 25px;
}

.metadataEditTextField {
  width: 467px;
}

.metadataEditMultilineTextField {
  width: 467px;
}

.metadataEditFieldLabel {
  font-size: 14px;
  line-height: 16px;
  color: $darkestGrey;
  margin-bottom: 4px;
}

.metadataEditStaticText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  width: 100%;
  overflow-wrap: anywhere;
}

.fileSeperator {
  margin-top: 25px;
  width: 100%;
  border: 1px solid $offWhite;
}

.ageDropDown {
  width: 82px;
}

:global(.lowAgeMenu) {
  & :global(.MuiPaper-root) {
    height: 16em;
  }
}

:global(.highAgeMenu) {
  & :global(.MuiPaper-root) {
    height: 16em;
  }
}

.ageResetButton {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  color: $primary;
  min-width: 0px;
}

.radio {
  height: 44px;
  width: 44px;
}

.radioText {
  color: $black;
}

.genderRadioButton {
  margin-bottom: -10px;
}

.metadataTypeSelect {
  width: 196px;
}

.metadataValueTextField {
  width: 361px;
}

.metadataCategoryListContainer {
  margin-top: 24px;
  width: 100%;
}

.metadataCategoryListTitle {
  margin-bottom: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $black;
}

.metadataCategoryList {
  border: 1px solid $gray;
  border-radius: 6px;
  padding: 5px;
  overflow: auto;
  height: 300px;
  width: 100%;
}

.conceptCategoryList {
  width: 617px;
}

.metadata {
  margin-bottom: 2px;
  border-radius: 4px;
  min-height: 34px;
}

.metadataItem {
  background: $offWhite;
}

.metadataItemNew {
  background: rgba(170, 226, 208, 0.51);
}

.metadataItemNew * {
  font-weight: 500 !important;
}

.metadataItemHealthwise {
  background: #fff;
}

.metadataItemText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  margin: -5px 0 -5px -10px;
}

.conceptItemText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
}

.conceptMetadataItem {
  min-height: 44px;
  width: 641px;
  border-radius: 8px;
  margin-top: 3px;
}

.removeMetadataItem {
  margin-right: -10px;
  color: $black;
  font-size: 4px;
}

.errorText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $red;
}

.readonlyTextLabel {
  width: 120px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $neutralGrey;
  text-align: end;
}

.readonlyText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  width: min-content;
}

.infoIcon {
  vertical-align: text-top;
  cursor: pointer;
  height: 20px;
  width: 20px;
  padding-left: 8px;
  color: $neutralGrey;
}

.deprecatedMsg {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 24px;
  gap: 16px;
  background: $pink;
  border-radius: 8px;
  width: 90%;
  font-weight: 700;
  line-height: 24px;
  color: $darkestGrey;
}

.metadataBtn {
  height: 44px;
}

.tooManyCharacterWarning {
  color: $red;
}

.fewRemainingCharacterWarning {
  color: $darkestBlack;
}

.sectionDetails {
  color: $darkGray;
  margin-left: 10px;
}
