@import 'Styles/_theme.scss';

.cartContent {
  border-bottom: 1px solid $lightGrey;
  box-shadow: 0 3px 5px 0 $lightestGray;
}

.cartItems {
  padding: 0 20px 10px 20px;
  margin-left: 3px;
  max-height: 260px;
  overflow: hidden auto;
  max-width: 1413px;
}

.cartRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
  border-radius: 8px;
  width: 100%;

  &:hover {
    background-color: $hoverWhite;
  }
}

.cartRow:last-child {
  margin-bottom: 0;
}

.triangle {
  position: absolute;
  right: 0;
  transform: translate(-43px, -24px);
}

.basketLabel {
  font-size: 20px;
  color: $darkestBlack;

  .basketCount {
    color: $neutralGrey;
    display: inline-block;
    margin-right: 15px;
  }
}

.cartHeader {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  padding: 26px 35px 5px 35px;
  max-width: 1413px;
  margin: auto;
}

.moreActionsAndPrintOptions {
  margin-left: auto;
  display: flex;
  gap: 12px;
}

.cartTitle {
  height: 29px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: $black;
  padding-left: 20px;
}

.clearRow {
  margin-top: 15px;
}

.clearCart {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-decoration-color: $primary;
    cursor: pointer;
  }
}

.emptyCart {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  color: $neutralGrey;
  margin: 10px 0 26px 0;
}

.textTitleAndDesc {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $ts-16;
  line-height: $ts-24;
  margin: 4px 0 0 24px;
  padding: 8px 0 8px;
  text-align: left;
  width: 0;

  .contentTitle {
    color: $primary;
    white-space: wrap;
    box-sizing: content-box;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .contentDesc {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $black;
  }
}
.cartPreview {
  padding: 15px;
}

.cartItemId {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: $ts-16;
  line-height: $ts-24;
  color: $neutralGrey;
  white-space: wrap;
  overflow-wrap: break-word;
  box-sizing: content-box;
  text-align: right;
  flex-grow: 1;
  min-width: 0;
  margin-top: 16px;
}

.cartItemType {
  color: $neutralGrey;
  margin-top: 16px;
  margin-right: 16px;
}

.cartCheck {
  margin: 10px 11px 10px 0;
}

.cartActions {
  padding-right: 15px;
  margin-top: 0;
}

.cartItemPreview {
  margin-top: 6px;
  padding-left: 14px;
}

.langError {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $red;
  padding-left: 24px;
  padding-right: 24px;
}

.previewError {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $red;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
}

.errorIcon {
  vertical-align: middle;
}

.contentTypeAndId {
  margin-left: auto;
  width: 25%;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
}

.splitButton {
  margin: 0 0 0 3px;
}

.splitButton :global(.hw-split-button-group) {
  width: 106px;
  height: 44px;
}

.moreActions {
  width: 163px;
}

.moreActions > button {
  background: #ffffff !important;
}

.morActionsItem {
  width: 131px;
}

.actionLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $black;
  padding-left: 13px;
}

.suppressionMessageContainer {
  width: 512px;
  height: 136px;
}

.suppressionMessageSubTitle {
  vertical-align: top;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: $black;
  margin-top: 20px;
}

.suppressionMessageText {
  vertical-align: bottom;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $black;
  margin-top: 35px;
}
