@import 'Styles/_theme.scss';

.quickFiltersAndFacetButton {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;

  .facetButton {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    margin-top: 43px;
  }
}

.quickFilters {
  display: flex;
  gap: 7px;

  .quickFilter {
    border-radius: 8px;
    border: 1px solid $neutralGrey;
    background-color: $white;
    color: $mediumGrey;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: none;
    padding: 4px 15px;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      color: $black;
      border: 1px solid $mediumGrey;
    }
  }

  .activeQuickFilter {
    display: flex;
    gap: 7px;
    border: 1px solid transparent;
    background-color: rgba(170, 226, 208, 0.51);
    color: $black;
    &:hover {
      color: $darkestBlack;
      border: 1px solid transparent;
    }
  }

  .disabledQuickFilter {
    background-color: $white;
    border: 1px solid $neutralGrey;
    color: $mediumGrey;
    opacity: 0.5;
  }
}

.quickFilterHeader {
  display: flex;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 21px;

  .quickFilterTitle {
    color: $darkestBlack;
    font-family: Roboto Slab;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .quickFilterCount {
    color: $neutralGrey;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.noResultsMessageContainer {
  height: fit-content;
  margin: 35px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  .noResultsMessageIcon {
    width: fit-content;
    height: fit-content;
  }

  .noResultsIconImage {
    background-color: rgba($primary, 0.1);
    border-radius: 1000px;
  }

  .sorryMessage {
    margin: 12px 0;
    font-size: 18px;
    align-self: flex-start;
  }

  .suggestionMessage {
    align-self: flex-start;
    font-weight: 500;
    margin-top: 7px;
  }

  .suggestionList {
    align-self: flex-start;
  }
}
