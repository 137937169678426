@import 'Styles/_theme.scss';

.selections {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  padding-bottom: 10px;
  margin-top: 0;
  column-gap: 12px;
  row-gap: 8px;
}

.filterChip {
  font-size: 16px;
  color: $darkestBlack;
  background-color: $lightGrey;
  svg {
    color: #676767 !important;
  }
}

.filterChip:focus-visible {
  outline: 2px dotted $black;
  outline-offset: 2px;
}

.clearAllChip {
  background-color: transparent;
  color: $primary;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-decoration: underline;
}

.clearAllChip span {
  padding: 0;
}

.clearAllChip:hover,
.clearAllChip:focus-visible {
  background-color: transparent;
}
