@import 'Styles/_theme.scss';

.group {
  width: 100%;
  margin-top: 21px;
  margin-bottom: 8px;
}

.groupHeader {
  display: flex;
  width: 100%;
  color: $black;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  margin: 8px 0 6px 0;
}

.groupHeaderTitle {
  flex-grow: 0;
}

.headerLine {
  flex-grow: 1;
  height: 1px;
  position: relative;
  top: 11px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: $lightGrey;
}

.groupChild {
  color: $primary;
  min-width: 23px;
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto;
  text-transform: none;
  text-align: left;
  justify-content: flex-start;
  font-weight: 400;
  padding: 0;
  width: 100%;
  &:hover {
    text-decoration: underline;
  }
}

.groupChildren {
  columns: 3;
}

.actionableShowAll {
  margin-top: 12px;
  flex-basis: 100%;
  max-width: 163px;
}

.showAll {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 100%;
  justify-content: flex-start;
  font-family: Roboto;
  font-size: 18px;
  text-transform: none;
  min-width: 0;
  color: $primary;
  padding-left: 0;

  &:hover {
    text-decoration: underline;
  }
}

.responsiveHeaderButton {
  color: $primary;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  width: 100%;
  justify-content: space-between;
  text-transform: none;
  border-bottom: 1px solid $offWhite;
  padding: 9px 20px;
}

.showAllButton {
  @extend .responsiveHeaderButton;
  font-weight: 500;
}

.jumpTo {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid $lightGrey;
  background: $white;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.jumpToHeader {
  display: flex;
  align-items: center;
  color: $black;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 17px;
  gap: 8px;
}

.jumpToSectionsGroup {
  columns: 3;
}

.jumpToSectionsAlpha {
  display: flex;
}

.jumpToItemGroup {
  @extend .groupChild;
}

.jumpToItemAlpha {
  @extend .groupChild;
  width: fit-content;
  margin-right: 10px;
  font-size: 20px;
}

.headers {
  display: block;
  columns: 3;

  .showAll {
    padding: 0;
    text-align: left;
  }
}
