@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.avatarOutter {
  height: 28px;
  width: 28px;
}

.avatarInner {
  height: 24px;
  width: 24px;
  border: 1px solid $white;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.badgeBackgroundActive {
  background-color: $primary;
}

.badgeBackgroundInactive {
  background-color: $neutralGrey;
}

.noRing {
  background-color: inherit;
}

.label {
  color: $darkestGrey;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.line {
  width: 75px;
  height: 0px;
  border-top: 1px solid $black;
  flex: none;
  order: 1;
  flex-grow: 0;
}
