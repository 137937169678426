@import 'Styles/_theme.scss';

html,
body,
.App,
.Page-Content,
.main-grid,
.main-content,
.Page-Content,
.main-grid {
  height: 100%;
}

.main-content {
  height: calc(100% - 72px);
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.menuContainer {
  height: 100%;
}

.navMenuSelected {
  background-color: black;
  color: white;
}

.navMenuItem {
  text-decoration: none;
  color: white;
  text-transform: capitalize;
  font-size: larger;
}

.navSmallMenuItem {
  text-decoration: none;
  color: black;
  text-transform: capitalize;
}

.header {
  background: rgb(0, 98, 114);
  height: 72px;
}

.headerLogo {
  margin: 10px;
  height: 50px;
}

.userHeader {
  text-align: right;
  margin: 10px;
}

.myhealthwiseLink {
  text-decoration: none;
  color: white;
  width: 200px;
}

// added for accessibility purposes
h1 {
  font-size: $ts-12;
  font-weight: 400;
  color: $offWhite;
  margin: 0;
  font-style: italic;
}

h2 {
  font-size: 25px;
  font-weight: 400;
  color: $darkPrimary;
  margin: 0;
}

h3 {
  font-weight: 700;
  font-size: $ts-16;
  line-height: 19px;
  color: $black;
  margin: 0;
}

h4 {
  font-weight: 400;
  font-size: $ts-20;
  color: $black;
  margin-top: 21.5px;
  margin-bottom: 6px;
}

h5 {
  color: $white;
  font-size: $ts-18;
  margin: 0;
  font-weight: 400;
}

.MuiTooltip-tooltip {
  font-size: $ts-14;
}

.MuiTab-root.Mui-selected {
  font-weight: bold;
  color: $white;
}

.MuiButton-root,
.MuiLink-root,
.MuiTab-root {
  @include dotted-outline-on-focus;
}

.MuiTab-root:focus {
  outline-offset: -1px;
}

.MuiRadio-root.Mui-checked {
  .MuiSvgIcon-root {
    z-index: 1;
  }

  .MuiTouchRipple-root {
    color: $gray;
  }
}
