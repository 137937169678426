@import 'Styles/_theme.scss';

.primary:not(.text.primary) {
  background: $green;
  color: $white;

  &:hover {
    background: $green;
    box-shadow: none;
  }

  &:active {
    background: $green;
  }
}

.secondary {
  color: $neutralGrey;
  background: $white;
  border: 1px solid $offWhite;

  &:hover {
    background: transparent;
    box-shadow: none;
  }

  &:active {
    background: $white;
  }
}

.cmxButton {
  border-radius: 8px;
  box-shadow: none;
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 44px;
  line-height: 19px;
  min-width: 65px;
  padding-left: 18px;
  padding-right: 18px;
  text-transform: none;
}

.cmxButton:focus-visible {
  box-shadow: none;
}

.cmxButton:disabled,
.cmxButton[disabled] {
  background-color: $gray;
  color: $darkGray;
  cursor: inherit;
}

.grayscale:disabled,
.grayscale[disabled] {
  background-color: $offWhite;
  color: $darkestGrey;
  opacity: 0.5;
  cursor: inherit;
}

.text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  border: none;

  box-shadow: none;
  min-width: 85px;
  text-transform: none;
  height: 40px;
  background: transparent;

  &:hover {
    background: transparent;
    box-shadow: none;
  }
  &:active {
    background: transparent;
    box-shadow: none;
  }
}

.text.primary {
  color: $primary;
  padding: 0;
  white-space: nowrap;
  margin-top: 6px;

  &:hover {
    text-decoration: underline;
  }
}

.grayscale {
  background-color: $offWhite;
  color: $darkestGrey;
  &:hover {
    background-color: $offWhite;
  }
}
