@import 'Styles/_theme.scss';

$fieldLabelWidth: 100px;
$rowGap: 26px;

.wrapper {
  padding: 0 20px;
  width: 100%;
  & hr {
    margin: 26px 0 0 0;
    border: 1px solid $lightGrey;
  }
}

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: $black;
  &:not(:first-child) {
    padding-top: 26px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $rowGap;
  padding-top: 26px;
}

.fieldLabel {
  display: flex;
  justify-content: flex-end;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $neutralGrey;
  width: $fieldLabelWidth;
}

.fieldValue {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  color: $darkestGrey;
  max-width: calc(100% - ($fieldLabelWidth + $rowGap));
  overflow-wrap: anywhere;
}

.blocks {
  padding: 26px 0 0 0;
  display: flex;
  flex-direction: row;

  & > div:not(:last-child) {
    padding-right: 25px;
  }
}

.blocks:not(:last-child) {
  padding-bottom: 25px;
}
