@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  & h2 {
    padding: 0 0 16px 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: $black;
  }
}

.uploadStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0 0;
  padding: 5px 0;

  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #424242;
}
.uploadStatusInfo {
  background-color: $tan;
}
.uploadStatusError {
  background-color: $lightRed;
}
.uploadStatusSuccess {
  background-color: $successGreen;
}

.fileSeperator {
  width: 100%;
  border: 1px solid $offWhite;
}

.errorText {
  margin: 16px 0 0 auto;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $red;
}

.fileListContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 15px;

  width: 100%;
  height: calc(100% - 200px);
  overflow: auto;
  overflow-x: hidden;
}

@media screen and (max-height: 599px) {
  .wrapper {
    & h2 {
      padding: 0;
    }
  }

  .uploadStatus {
    margin: 5px 0 0 0;
    padding: 2px 0;
  }

  .errorText {
    margin: 0;
  }
}
