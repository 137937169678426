@import 'Styles/_theme.scss';

.SkipToContentLinkBar {
  background-color: white;
  height: 0;
  opacity: 0;
  position: relative;
  width: 100vw;
  z-index: 0;
  top: 0;
  &:focus-within {
    height: 46px;
    opacity: 100;
  }

  .SkipToContentLink {
    background-color: white;
    color: $primary;
    z-index: 0;
    &:focus {
      left: 16px;
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      opacity: 100;
      outline-style: dashed;
      outline-width: 2px;
      padding: 4px;
      z-index: 200;
    }
  }
}
