@import 'Styles/_theme.scss';

.previewModalContent {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.previewModalWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 30vh;
  overflow-y: auto;
}

.previewTitle {
  line-height: 29px;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  color: #424242;
  width: 100%;
}

.previewControls {
  display: flex;
  flex-direction: row;
  gap: 5px;
  float: right;
  margin: 3px 10px 5px 20px;
}

.previewHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.checkbox {
  margin-right: 0.75vw;

  &:has(:focus-visible) {
    outline: 2px dotted $black;
  }
}

.moreActions {
  width: 163px;
  & li:hover,
  li:focus-visible {
    background-color: $lightGrey;
  }
}

.morActionsItem {
  width: 131px;
}

.actionLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  padding-left: 13px;
}

.printButton {
  margin-left: 12px;

  .printIcon {
    margin-right: 7px;
  }
}

.previewClose {
  height: 40px;
  width: 40px;

  &:focus-visible {
    outline: 2px dotted $black;
  }
}

.tab {
  text-transform: capitalize;
  font-size: 18px;
  color: $mediumGrey;
  font-weight: 400;
  letter-spacing: 0;
}

.suppressionMessageContainer {
  width: 512px;
  height: 136px;
}

.suppressionMessageSubTitle {
  vertical-align: top;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: $black;

  margin-top: 20px;
}

.suppressionMessageText {
  vertical-align: bottom;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: $black;
  margin-top: 35px;
}

.unavailableMessage {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $red;
}
