@import 'Styles/_theme.scss';

.group {
  box-shadow: none;
}

.group > button {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 8px;
  height: 44px;
  background: $offWhite;
  color: $black;
}

.label {
  border-right: 1px solid $white !important;
}
