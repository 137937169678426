@import 'Styles/_theme.scss';

.manageContentItemPage {
  height: auto;
}

.manageContentItemPageContent {
  max-width: 1440px;
}

.modalConfirm {
  width: 560px;
  height: 217px;
  padding: 15px 24px 15px 24px;
}

.modalConfirmTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;

  /* primary-dark */

  color: $darkPrimary;
}

.modalMessage {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.BackLink {
  border-radius: 0;
  color: $primary;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 29px 0px 27px 25px;
  text-transform: none;
}

.manageControlPanelItem {
  width: 282px;
}

.menuItem {
  &:hover,
  &:focus {
    background-color: $lightGrey;
  }

  &:global(.Mui-selected) {
    background-color: $white;
  }

  &:global(.Mui-selected):hover {
    background-color: $lightGrey;
  }
  &:global(.Mui-selected):focus {
    background-color: $lightGrey;
  }
}

.manageControlPanelLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $neutralGrey;
  margin-bottom: 8px;
}

.manageControlPanelDesc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: rgba($darkestBlack, 0.5);
  max-width: 282px;
}

.manageControlPanelInfo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: $black;
}

.checkLabel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.deleteContentItem {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $red;
  padding: 0px;
  text-transform: none;
}

.buttonLoader {
  color: #727272;
  height: 26px;
  margin-left: 120px;
}

.manageContentItemTabPanel {
  width: 100%;
  overflow: hidden;
  align-self: stretch;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}
