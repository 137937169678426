@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tooltip {
  top: 5px;
  width: 387px;
  padding: 7px;
  border-radius: 3px;
}

.message {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: $white;
}

.errorIcon {
  color: $red;
}
