@import 'Styles/_theme.scss';

.browseBreadCrumbs {
  margin: 32px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
  color: $black;

  a {
    color: $primary;
    text-decoration-line: underline;

    &:focus-visible {
      outline: 2px dotted $black;
      outline-offset: 2px;
    }
  }

  p {
    color: $black;
  }
}
