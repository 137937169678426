@import 'Styles/_theme.scss';

.header {
  padding: $ss-24 0 $ss-12 $ss-24;
}

.name {
  font-size: $ts-18;
  font-weight: bold;
  color: $darkestGrey;
  margin: 0px;
}

.email {
  font-size: $ts-14;
  color: $neutralGrey;
  max-width: 205px;
  word-wrap: break-word;
  margin: 0px;
}

.nav {
  color: $darkestGrey;
  display: flex;
  flex-direction: column;
}

.myHWMenuUl {
  margin: 0;
  padding: 0 $ss-24;
}

.myHWMenuLi {
  display: block;
}

.myHWMenuLi:focus:not(:focus-visible) {
  outline: none;
}

.myHWLink {
  display: block;
  height: $ss-48;
  padding: $ss-12 $ss-24 $ss-12 $ss-42;
  margin: 9px 12px;
  box-sizing: border-box;
  color: $darkestGrey;
  font-size: $ts-16;
  font-weight: 400;
  background-color: $offWhite;
  border-radius: $ss-8;
  text-decoration: none;

  &:link,
  &:visited,
  &:active {
    text-decoration: none;
  }

  &:hover {
    outline: 2px $darkestGrey;
  }

  &:global(.MuiLink-root:focus),
  &:global(.MuiLink-root:focus-visible) {
    outline: 2px dotted;
    outline-offset: -3px;
    background-color: $offWhite;
  }
}

.myHwIcon {
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: bottom;
  margin-right: $ss-14;
}

.menuUl {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0px;
}
.menuLi {
  display: block;
}

.menuLiHide {
  visibility: hidden;
  height: 0;
}

.userInfoContainer {
  background-color: $white;
  border: 1px solid $lightGrey;
  border-radius: 8px;
  min-width: 300px;
  position: absolute;
  right: 0;
  box-shadow: 0px 10.5px 36px 0px rgba(0, 0, 0, 0.19), 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
  z-index: 902;
}

.profileButton {
  height: 24px;
  width: 24px;
  margin-left: 6px;

  &:hover {
    background: transparent;
  }
}

.invisible {
  display: none;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: bottom;
  margin-right: $ss-14;
}

.arrowDownIcon {
  fill: $white;
}

.link {
  display: block;
  height: $ss-48;
  padding: $ss-12 $ss-24;
  box-sizing: border-box;
  color: $darkestGrey;
  font-size: $ts-16;
  font-weight: 400;
  text-decoration: none;

  &:link,
  &:visited,
  &:active {
    text-decoration: none;
  }

  &:hover {
    outline: 2px $darkestGrey;
    background-color: $offWhite;
  }

  &:global(.MuiLink-root:focus),
  &:global(.MuiLink-root:focus-visible) {
    outline: 2px dotted;
    outline-offset: -3px;
    background-color: $offWhite;
  }
}

.navDiv {
  &:not(:last-child) {
    margin-bottom: $ss-8;
  }
}

.divider {
  border-bottom: 1px solid $offWhite;
  margin-top: $ss-12;
  margin-bottom: $ss-12;
}

.signOutMenuUl {
  margin: 0;
  padding-left: 0px;
  padding-bottom: $ss-12;
}

.textPadding {
  padding-left: $ss-8;
}

.bottomPadding {
  padding-bottom: 12px;
}

.myHwPadding {
  padding: 0 12px;
}
