#HwColumnSidebar1 {
  width: 0px !important;
}

#HwColumnSidebar2 {
  width: 0px !important;
}

#HwColumnContent {
  width: 100% !important;
}

.HwCmxNavigationSection .HwSectionTitle {
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: solid 1px #424242;
}

#HwContentTitle {
  font-style: normal;
}

.HwPiArticle ul.hw-vl-list {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.HwPiArticle ul.hw-vl-list li {
  display: table-row;
  margin: 0;
  list-style: none;
}

.HwPiArticle .hw-vl-list li figure {
  display: table-cell;
}

.HwPiArticle .hw-vl-list li figure img {
  width: 3em;
}

.HwPiArticle .hw-vl-list li figure.HwMedicalImage {
  text-align: left;
  margin-right: 2em;
}

.HwPiArticle p.hw-vl-caption {
  font-weight: 400;
  width: 80%;
  display: table-cell;
}

.HwTypeVisualList .HwContentTitle #HwContentTitle,
.HwTypeAfterVisit .HwContentTitle #HwContentTitle {
  width: 100%;
}
