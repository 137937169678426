@import 'Styles/_theme.scss';

.title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: $black;
}

.finalizeMessage {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.progressBar {
  width: 67%;
  height: 4px;
}

.finalizeUploadImage {
  padding-top: 35px;

  margin-bottom: -15px;
  width: 680px;
  height: 200px;
  background-image: url('/Resources/header_design.svg');
  background-repeat: no-repeat;
  background-position: center 90%;
}

.errorsContainer {
}

.errorRow {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $red;
}
