@import 'Styles/_theme.scss';

/* The Close Button */
.modalClose {
  color: $darkestGrey;
  height: 29px;
  width: 29px;
}

/* Modal Content/Box */
.modalContent {
  min-height: 100px;
  max-height: 80vh;
  background-color: #fefefe;
  margin: 50px auto; /* 50px (close to 48dp recommended for design) from the top and centered */
  max-width: 850px;
  box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.039), 0px 10.5px 36px rgba(0, 0, 0, 0.19);
  border-radius: 8px;
}
