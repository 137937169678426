@import 'Styles/_theme.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.loader {
  color: $darkPrimary !important;
}
