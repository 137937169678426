@import 'Styles/_theme.scss';

$modalPadding: 24px;

/* Modal Content/Box */
.modalContent {
  max-width: 560px;
  max-height: 550px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $modalPadding;
  gap: 20px;
  background-color: $white;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 50px auto;
}

.banner {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
}

.title {
  font-size: 25px;
  line-height: 29px;
  font-weight: 700;
  color: $red;
}

.closeModal {
  margin-left: auto;
  color: $darkestGrey;
}

.warningMessage {
  width: 100%;
  margin-left: -$modalPadding;
  margin-right: -$modalPadding;
  padding: 12px $modalPadding;
  background-color: $tan;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  color: $darkestGrey;
  line-height: 24px;
}

.confirmationMessage {
  color: $darkestGrey;
  margin-bottom: 10px;
}

.controls {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: flex-end;
  gap: 8px;
}

.restoreToDefaultButton {
  background-color: $red;
  color: $white;
}

.restoreToDefaultButton:hover {
  background-color: $red;
  color: $white;
}
