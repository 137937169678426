@import 'Styles/_theme.scss';

.configRequiredHeaderContainer {
  background-color: $tan;
  height: 68px;
}

.configRequiredHeader {
  padding-left: 20px;
  padding-top: 3px;
  width: 100%;
  height: 68px;
}

.configRequiredHeaderErrorIcon {
  height: 20px;
  width: 20px;
  color: $darkestGrey;
}

.configRequiredHeaderText {
  line-height: 24px;
}

.appBar {
  background: $darkPrimary;
  color: $white;
  box-shadow: none;
  flex: 0 1 auto;
  height: 72px;
  z-index: 7;
  top: 0;
  z-index: 1100;
  width: 100%;
}

.profile {
  background: $primary-color;
  height: 72px;
  margin-right: -24px;
  min-width: 180px;
}

.accountIcon {
  color: $white;
  font-size: $ts-38;
  vertical-align: middle;
  display: inline-flex;
  padding-right: $ss-8;
}

.account {
  margin: $ss-16;
}

.tool {
  color: $white;
  font-size: $ts-12;
  font-style: italic;
}

.name {
  font-size: $ts-18;
  line-height: $ss-12;
}

.tab {
  color: $white;
  font-size: $ts-18;
  font-weight: 300;
  height: 72px;
  opacity: 1;
  text-transform: capitalize;

  &:hover {
    background-color: $primary-color;
  }
}

.tabIndicator {
  height: '4px';
}

.tabButton {
  min-height: 42px;
  text-transform: capitalize;
  font-size: $ts-16;
  border-radius: $ss-20;
  margin: $ss-15 $ss-20 0 $ss-20;
  letter-spacing: 0.13px;
  padding-left: 18px;
  padding-right: 18px;
  opacity: 1;
}

.tabButtonUnselected {
  font-weight: 500;
  color: $darkPrimary;
  background-color: $white;
  &:focus-visible {
    border: 2px dotted $primary-color;
  }
}

.tabButtonSelected {
  color: $white;
  border: 2px solid #ffffff;
  background-color: $darkPrimary;
  &:focus-visible {
    border: 2px dotted $white;
  }
}

@media only screen and (max-width: 700px) {
  .profile {
    background: $primary-color;
    height: 72px;
    width: 80px;
  }
  .tool {
    display: none;
  }
  .accountIcon {
    display: none;
  }
}

.logo {
  width: 380px;
}

.logoWrapper {
  height: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include dotted-outline-on-focus;
}

.profile {
  background: $primary-color;
  height: 72px;
  margin-right: -24px;
  min-width: 180px;

  &:has(:focus-visible) {
    outline: 2px dotted $white;
    outline-offset: -3px;
  }
}

.userMenuArrow {
  color: $white;
}

.application {
  height: 25px;
  width: 100%;
  font-size: 12px;
  font-style: italic;
  color: $white;
  margin-left: 50px;
  margin-top: -10px;
}

.subHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $lightestGray;
  color: $neutralGrey;
  position: relative;
  z-index: 7;
  height: 92px;
}

.subHeaderToolbar {
  width: 100%;
  height: 100%;
  max-width: 1376px;
  display: flex;
}

.subHeaderTitle {
  color: $darkestBlack;
  font-family: Roboto Slab;
  text-wrap: nowrap;
  font-size: 26px;
  margin: 22px 15px 22px 0;
}

.pageDropdowns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 15px 0 15px;
  gap: 3px;
}

.pageDropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 3px;
  font-family: Roboto;
  font-size: 18px;
  text-transform: none;
  height: 100%;
  min-width: 0;
  color: $neutralGrey;
}

.pageDropdown:hover {
  color: $black;
}

.pageDropdownActive {
  @extend .pageDropdown;
  color: $darkestBlack;
}

.pageDropdownActive:hover {
  color: $darkestBlack;
}

.pageDropdownLabel {
  font-size: 18px;
  white-space: nowrap;
}

.searchBar {
  flex-grow: 1;
}

.searchButtons {
  display: flex;
  flex-direction: row;

  .actionIcon:focus-visible {
    outline: 2px dotted $black;
  }
}

.triangle {
  position: absolute;
  top: 41px;
  left: 30px;
}
